import * as React from "react"
import type { HeadFC } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Pdf from "../components/Pdf";

export function Logo() {
  return <StaticImage
    src="../images/logo.jpg"
    alt="Logo Veracitrus"
    placeholder="blurred"
    layout="fixed"
    height={100}
  />
}

export function SitioContruccion() {
  return <StaticImage
    src="../images/Sitio-construccion.png"
    alt="Sitio en construccion"
    layout="fullWidth"
    placeholder="blurred"
  />
}

const IndexPage = () => {
  return (
    <main >
      {Logo()}
      <div className="center">{SitioContruccion()}</div>
      <Pdf />
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Home Page</title>
