import React, { useState } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import { SizeMe } from 'react-sizeme'

import anuncioPDF from '../../anuncio.pdf';

export default function Pdf() {
  const [numPages, setNumPages] = useState(null);

  pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  return (
    <SizeMe>
      {({ size }) => (
        <Document
          file={anuncioPDF}
          options={{ workerSrc: "/pdf.worker.js" }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={size.width ? size.width : 1} />
          ))}
        </Document>
      )}
    </SizeMe>
  );
}